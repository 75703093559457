<template>
  <div style="height: 100%; width: 100%">
    <WujieVue class="item" name="demo-vue2" :url="vue2Url" :sync="true"></WujieVue>
  </div>
</template>

<script>
import hostMap from "../hostMap";

export default {
  data() {
    return {
      vue2Url: hostMap("//127.0.0.1:7200/"),
    };
  },
  methods: {
    jump(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style scoped>
.item {
  display: inline-block;
  border: 1px dashed #ccc;
  border-radius: 8px;
  width: 45%;
  height: 45%;
  margin: 20px;
  overflow: scroll;
}
</style>
