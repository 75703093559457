<template>
  <!--单例模式，name相同则复用一个无界实例，改变url则子应用重新渲染实例到对应路由 -->
  <WujieVue 
    width="100%" 
    height="100%" 
    name="demo-vue2" 
    :url="vue2Url" 
    :sync="true"
  ></WujieVue>
</template>

<script>
import hostMap from "../hostMap";

export default {
  data() {
    return {
      vue2Url: hostMap("//127.0.0.1:7200/"),
    };
  },
  methods: {
    // jump(location, query) {
    //   // 跳转到主应用B页面
    //   this.$router.push(location);
    //   const url = new URL(window.location.href);
    //   url.search = query
    //   // 手动的挂载url查询参数
    //   window.history.replaceState(null, "", url.href);
    // }
    jump(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
